<!-- No longer used, was replaced by the agent directory for AOs and the new agent detail pages...  -->
<template>
    <div>
        <v-progress-linear indeterminate v-if="loading || agent_loading"></v-progress-linear>
        <q-drilldown-agent-data-table 
            :allow-drilldown="false" 
            :loading="loading || agent_loading" 
            :title="the_title" :the-data="the_data" 
            :headers="headers" 
            v-on:filters="updateFilters" 
            :filters="filters" 
            @reload="loadData" 
            :agent-code="agent_code" 
            :export-fields="export_fields"
            :search.sync="search"
        >
            <template #append-top v-if="the_data && the_data.length > 0">
                <p class="px-5">Note: Shows the agents who wrote business in the given time frame</p>
            </template>
            <template #filters>
                <q-business-time-period-select 
                    ref="periodSelect"
                    v-model="datePeriod"
                    label="Time Period"
                    :allow-current="true"
                    dont-reset
                    hideAllOption
                    stateKey="stat"
                />
            </template>
            <template #no-data>
                <div class="px-5">
                    No Data Is Available
                </div>
            </template>
			<template v-slot:item.SavedPremium="{ item }">
				<span class="nowrap">{{ formatLongCurrency(item.SavedPremium) }}</span>
			</template>
        </q-drilldown-agent-data-table>
    </div>
</template>
<script>
/*
Force re-rendering of this component on this view...
set a key and anytime the agentcode changes we will increment the componentKey and it will re-render the component. 
 */

import QDrilldownAgentDataTable from '@/components/datatables/Drilldowns/QDrilldownAgentDataTable.vue';
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QScoreCardPieChart from '@/components/stats/scorecards/QScoreCardPieChart.vue'
export default {
    name: "QConservedPoliciesDrilldown",
    props: {
        'agentCode': {
            type: [String, Number],
            default: ''
        },
    },
    data() {
        return {
            the_data: null,
            agent: null,
			agentName: '',
            loading: false,
            agent_loading: false,
            backup_filters: {},
            filters: {
                endDate: this.$route.query.endDate || null,
                startDate: this.$route.query.startDate || null,
                timePeriod: this.$route.query.timePeriod ? this.$route.query.timePeriod : 'month',
                statType: this.$route.query.statType ? this.$route.query.statType : 'TotalAgency'
            },
            search: '',
            selectedDateLabel: '',
        }
    },
    activated: function() {
        this.backup_filters = this.filters
		this.loadAgent(this.agent_code).then(() => {
			this.loadData();
		})
    },
    computed: {
        datePeriod: {
            get: function() {
                return {
                    endDateFormat: this.filters.endDate,
                    startDateFormat: this.filters.startDate,
                    timePeriod: this.filters.timePeriod,
                    label: this.selectedDateLabel,
                }
            },
            set: function(newV) {
                if (this.filters.endDate == newV.endDateFormat && this.filters.startDate == newV.startDateFormat && this.filters.timePeriod == newV.timePeriod) {
                    return
                }

                this.selectedDateLabel = newV.label

                this.filters = {
                    startDate: newV.startDateFormat,
                    endDate: newV.endDateFormat,
                    timePeriod: newV.period,
                    statType: this.filters.statType
                }
            }
        },
        agent_code: function() {
            if(this.agentCode != null && this.agentCode != ''){
                return this.agentCode;
            }
			const g = this
            if (typeof g.$route.params.agent_code != 'undefined' && g.$route.params.agent_code != null) {
                return g.$route.params.agent_code
            } 
            return g.user.Agent.AgentCode;
        },
        headers: function() {
            var r = [{
                    text: 'Saved Premium',
                    value: 'SavedPremium',
                    align : "right"
                },
                {
                    text: 'Carrier',
                    value: 'Carrier',
                },
                {
                    text: 'Client',
                    value: 'Client',
                },
                {
                    text: 'Policy Number',
                    value: 'PolicyNumber',
                },
                {
                    text: 'Saved Date',
                    value: 'SavedDate',
                },
                {
                    text: 'Product Type',
                    value: 'ProductType',
                },
            ]
            if (this.hasRole("SuperAdmin", "Staff", "Exec")){
                r.unshift(
                    {
                        text: 'Agent',
                        value: 'AgentName',
                    }
                )
            }
            return r;
        },
        the_title: function() {
            if (typeof this.agent == 'undefined' || this.agent == null) {
                return 'Conserved Policies Details'
            }
			let agentName = this.agent.AgentName ? this.agent.AgentName : this.user.Agent.AgentName
            return agentName + ' Conserved Policies Details'
        },
        pie_chart_data: function() {
            if (this.the_data == null) {
                return
            }
            var r = {}
            this.the_data.forEach(function(i) {
                if (typeof r[i.LeadershipLevel] != 'undefined') {
                    r[i.LeadershipLevel] = r[i.LeadershipLevel] + i.Apps;
                } else {
                    r[i.LeadershipLevel] = i.Apps;
                }
            })
            return r;
        },
        export_fields: function() {
            return ['AgentID', 'AgentCode', 'AgentName', 'SavedPremium', 'Carrier', 'Client', 'PolicyNumber',  'SavedDate', 'ProductType'];
        }
    },
    methods: {
        updateFilters: function(f) {
            this.filters = f
            this.backup_filters = this.filters
        },
        loadData: function() {
            this.loading = true
            if (typeof this.filters == 'undefined' || typeof this.filters.timePeriod == undefined) {
                this.filters = this.backup_filters
                return
            }
            let params = {
                ...this.filters
            }
            if (params.startDate == null){
                var d = this.getBusinessMonths()
                console.log(d)
                params.startDate = d.beginDate;
                params.endDate = d.endDateFormat;
            }
            this.the_data = []
            let q_name = "getDrillDownConservedPolicies"
			QuilityAPI.cancelQRequest(q_name)
            QuilityAPI.getRequest("/api/private/scorecards/conserved_policies/" + this.agent_code + "/drilldown", params, q_name).then(
                (json) => {
                    this.the_data = json.data
                    this.loading = false;
                },
                (error) => {
                    this.loading = false;
                    console.log(error)
                    return error
                }
            )
        },
        loadAgent: function(agentCode) {
            const g = this
            g.agent_loading = true;
			return QuilityAPI.getAgentStateless(agentCode).then(function(json) {
				g.$set(g, 'agent', json)
				g.agent_loading = false
			}).catch(function(err) {
				console.log(err)
				g.agent_loading = false
			})
        },
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.loadData();
            }
        },
        'agent_code': function(newV) {
            this.agent = null
            this.loading = true
            this.the_data = []
			this.loadAgent(this.agent_code).then(() => {
				this.loadData();
			})
        },
        '$route.query' : {
            deep: true,
            handler: function(newV, oldV) {
                var g = this
                if (typeof newV == 'undefined') {
                    return
                }

                if (Object.keys(newV).every((k) => newV[k] === oldV[k])) {
                    return
                }

                if (g.filters.endDate != newV.endDate || g.filters.startDate != newV.startDate || g.filters.timePeriod != newV.timePeriod) {
                    g.$nextTick(function() {
                        g.updateFilters({
                            startDate: newV.startDate,
							endDate: newV.endDate,
                            timePeriod: newV.timePeriod,
                            statType: newV.statType
                        })
                    })
                }
            }
        }
    },
    components: {
        QDrilldownAgentDataTable,
        QBusinessTimePeriodSelect,
        QScoreCardPieChart
    }
}
</script>
<style scoped>
</style>